<div class="flex flex-row items-center space-x-2">
  <a
    [routerLink]="['../../../']"
    class="text-dms-teal flex flex-shrink font-bold"
  >
    {{ module | moduleType }}
  </a>
  <div class="flex flex-none flex-row items-center">
    <dominion-icon-right-caret
      height="h-3"
      width="w-3"
      strokeWidth="stroke-2"
    ></dominion-icon-right-caret>
  </div>

  @if (group) {
    <a
      [routerLink]="['../../../']"
      class="text-dms-teal flex flex-shrink cursor-pointer font-normal"
    >
      {{ discriminator | submodulePhase }}
    </a>
    <div class="flex flex-none flex-row items-center">
      <dominion-icon-right-caret
        height="h-3"
        width="w-3"
        strokeWidth="stroke-2"
      ></dominion-icon-right-caret>
    </div>
    <div @zoom class="relative flex flex-shrink font-light text-gray-600">
      {{ group }}
    </div>
  } @else {
    <div class="text-dms-teal flex flex-shrink font-normal">
      {{ discriminator | submodulePhase }}
    </div>
  }
</div>
