import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import {
  ResponseOptions,
  TDiscoveryDataPartial,
  TGenericQStruct,
} from '@dominion/interfaces';

function getMatchingOptionLabel(
  value: string | number,
  options: ResponseOptions[],
) {
  return options.find((option) => option.value === value)?.label || value;
}

@Pipe({
  name: 'questionDataPipe',
  standalone: true,
})
export class QuestionDataPipe implements PipeTransform {
  transform(
    value: TGenericQStruct<string>,
    data: TDiscoveryDataPartial,
  ): string | null {
    if (!data) {
      return null;
    }

    const questionData = (data as any)[value.questionKey];

    if (questionData === undefined) {
      return null;
    }

    if ('value' in questionData && questionData.value === null) {
      return null;
    }

    switch (value.componentType) {
      case 'text':
      case 'textarea':
        return questionData.value;
      case 'dropdown':
      case 'select':
        return getMatchingOptionLabel(
          questionData.value,
          value.options,
        ).toString();
      case 'select-list':
        return getMatchingOptionLabel(
          questionData.value,
          value.options,
        ).toString();
      case 'address':
        return `${questionData.value.street} ${questionData.value.city}, ${questionData.value.state} ${questionData.value.zip}`;
      case 'date':
        return new DatePipe('short').transform(questionData.value) || '';
      case 'month-day':
        return `${questionData.value.month} ${questionData.value.day}`;
      case 'file':
        return (
          questionData.value
            .map((file: { name: string }) => `File: ${file.name}`)
            .join('\n') || 'No File Uploaded'
        );
      case 'vendor':
        return questionData.value ? 'Vendor Selected' : 'No Vendor Selected';
      case 'contact':
        return questionData.value ? 'Contact provided' : 'No Contact Provided';
      default:
        return '[Unknown Response]';
    }
  }
}
