<div
  class="relative aspect-square h-full w-full"
  [ngClass]="{ 'cursor-pointer': canEditModuleDetails }"
>
  @if (isLocked) {
    <!-- LOCKED -->
    <div dominionPopoverHost [hoverTrigger]="true" class="h-full w-full">
      <div
        dominionPopoverTarget
        class="h-full w-full rounded-full bg-gray-200 p-[15%]"
      >
        <dominion-icon-lock-locked
          [strokeColor]="'stroke-gray-600'"
        ></dominion-icon-lock-locked>
      </div>
      <div dominionPopover class="w-48">
        <span class=""> Phase {{ stage }} is locked.</span>
        @if (canEditModuleDetails) {
          <span> Click to unlock.</span>
        }
      </div>
    </div>
  } @else if (completion < 100) {
    <!-- INCOMPLETE -->
    <div dominionPopoverHost [hoverTrigger]="true" class="h-full w-full">
      <div
        dominionPopoverTarget
        class="bg-dms-mid-green h-full w-full rounded-full p-[20%]"
      >
        <dominion-icon-ellipsis
          [strokeColor]="'stroke-white'"
        ></dominion-icon-ellipsis>
      </div>
      <div dominionPopover class="w-48">
        <span>Phase {{ stage }} is {{ completion }}% complete.</span>
        @if (canEditModuleDetails) {
          <span> Click to lock.</span>
        }
      </div>
    </div>
  } @else if (completion === 100) {
    <!-- COMPLETE -->
    <div dominionPopoverHost [hoverTrigger]="true" class="h-full w-full">
      <div
        dominionPopoverTarget
        class="h-full w-full rounded-full bg-gray-600 p-[25%]"
      >
        <dominion-icon-check
          [strokeColor]="'stroke-white'"
        ></dominion-icon-check>
      </div>
      <div dominionPopover class="w-48">
        <span>Phase {{ stage }} is complete.</span>
      </div>
    </div>
  }
</div>
