<div class="mb-5 flex flex-row items-center px-4">
  <div class="flex flex-row items-center space-x-1 pr-4 md:space-x-2 md:pr-6">
    <span class="text-xs uppercase text-gray-500 md:text-sm"
      >Onboarding<span class="hidden md:inline"> Project</span></span
    >
    <dominion-information-button
      [buttonHeight]="'h-3 md:h-4'"
      [buttonWidth]="'w-3 md:w-4'"
    >
      <div class="flex w-72 flex-col space-y-2 p-2 text-sm">
        <p>
          This section contains a list of <strong>modules</strong>. Each module
          corresponds to a department within your dealership, like Sales or
          Accounting, and requests important information about how you operate.
        </p>
        <p>
          You and your team will complete each module. Your DominionDMS
          deployment team will use the information that you provide to tailor
          VUE's data, configurations, and integrations to your business.
        </p>
      </div>
    </dominion-information-button>
  </div>
  <!-- ADD MODULE BUTTON -->
  <div
    *ngIf="canAddModule && !isParentCompany"
    class="fill-dms-green [&_circle]stroke-dms-green text-dms-green flex cursor-pointer items-center stroke-white text-sm"
    (click)="openModuleModal()"
  >
    <div class="relative mr-1 h-5 w-5">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <defs>
          <style>
            .i-new {
              stroke-linecap: round;
              stroke-linejoin: round;
              stroke-width: 1.2px;
            }
          </style>
        </defs>
        <title>{{ 'Invite a User' }}</title>
        <circle class="i-new" cx="12" cy="12" r="11.25" />
        <line class="i-new" x1="12" y1="7.5" x2="12" y2="16.5" />
        <line class="i-new" x1="7.5" y1="12" x2="16.5" y2="12" />
      </svg>
    </div>
    <span><span class="hidden md:inline">Add </span>Module</span>
  </div>
  <div class="flex-1"></div>
  <div class="ml-6 flex flex-shrink flex-row space-x-[2px]">
    <!-- VENDOR CENTER BUTTON -->
    <div
      class="hover:border-dms-teal hover:text-dms-teal flex cursor-pointer flex-row items-center justify-center rounded-sm rounded-br-none rounded-tr-none border border-solid border-gray-300 px-2 py-1 text-center text-sm text-gray-500 hover:shadow"
      [routerLink]="['/core', companyId, 'vendors']"
    >
      <span class="hidden md:inline">Vendor Center</span>
      <span class="md:hidden">Vendors</span>
    </div>
    <!-- VENDOR CENTER BUTTON -->
    <div
      class="hover:border-dms-teal hover:text-dms-teal flex cursor-pointer flex-row items-center justify-center rounded-sm rounded-bl-none rounded-tl-none border border-solid border-gray-300 px-2 text-center text-sm text-gray-500 hover:shadow"
      [routerLink]="['/core', companyId, 'documents']"
    >
      <span class="hidden md:inline">Document Center</span>
      <span class="md:hidden">Documents</span>
    </div>
  </div>
</div>

<!--  -->
<!--  -->
<!-- NO MODULES INDICATOR -->
<div
  *ngIf="!isLoading && modules?.length === 0"
  class="px-4 text-sm font-light italic text-gray-600"
>
  No project modules have been assigned yet
</div>

<!--  -->
<!--  -->
<!-- IS LOADING INDICATOR -->
<div *ngIf="isLoading" class="mt-4 flex h-8 flex-row">
  <dominion-loading-placeholder></dominion-loading-placeholder>
</div>

<!--  -->
<!--  -->
<!-- MODULE LIST -->
@if (!isLoading && modules && modules.length > 0) {
  <div class="group flex flex-col">
    @for (module of modules; track module._id) {
      <!-- EXPANDABLE HOST -->
      <div
        class="group/module flex flex-col border-b border-b-gray-100 first:border-t first:border-t-gray-100 group-hover:border-gray-200 group-hover:hover:bg-white"
      >
        <div
          class="grid grid-cols-[150px,3fr,100px] gap-4 px-4 py-3 md:grid-cols-[160px,3fr,100px] lg:grid-cols-[200px,3fr,100px] xl:grid-cols-[250px,3fr,150px] 2xl:grid-cols-[350px,3fr,150px]"
        >
          <div class="flex flex-col justify-between">
            <!-- MODULE NAME -->
            <div class="flex text-base font-semibold lg:text-lg">
              <dominion-in-situ-text
                #moduleName
                [initialValue]="
                  module.name === ''
                    ? (module.moduleType | moduleType)
                    : module.name
                "
                [isDisabled]="!canEditModuleDetails"
                [maxLength]="25"
                [minLength]="3"
                [defaultValue]="module.moduleType | moduleType"
                formErrMsg="Please enter a module name"
                (saved)="
                  updateModuleDetails(
                    {
                      field: 'name',
                      value: $event,
                      moduleId: module._id
                    },
                    moduleName
                  )
                "
              ></dominion-in-situ-text>
            </div>

            <!-- RESPONSIBLE USER -->
            <div
              class="flex flex-shrink items-center"
              [ngClass]="{
                'font-medium text-gray-700': module.externalAssigned[0],
                'text-gray-500': !module.externalAssigned[0]
              }"
            >
              @if (module.externalAssigned[0]) {
                <div
                  class="bg-dms-light-green ml-[3px] mr-1 flex h-3.5 w-3.5 items-center justify-center rounded-sm text-xs font-medium uppercase text-white"
                >
                  {{
                    (module.externalAssigned[0] | moduleActiveAssignedUser)
                      ?.label?.[0]
                  }}
                </div>
              } @else {
                <div class="relative ml-[3px] mr-1 h-3.5 w-3.5">
                  <svg
                    id="Regular"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    class="text-gray-400"
                  >
                    <defs>
                      <style>
                        .i-user-avi {
                          fill: none;
                          stroke: currentColor;
                          stroke-linecap: round;
                          stroke-linejoin: round;
                          stroke-width: 2px;
                        }
                      </style>
                    </defs>
                    <title></title>
                    <circle class="i-user-avi" cx="12" cy="9.75" r="5.25" />
                    <path
                      class="i-user-avi"
                      d="M18.913,20.876a9.746,9.746,0,0,0-13.826,0"
                    />
                    <circle class="i-user-avi" cx="12" cy="12" r="11.25" />
                  </svg>
                </div>
              }
              <dominion-dropdown-search
                #assignedUser
                [options]="module | getModuleAssignedUserOptions: company"
                [viewType]="'editable'"
                [togglePaddingX]="'px-[1px]'"
                [togglePaddingY]="'py-[1px]'"
                [toggleText]="'Assign User'"
                [toggleTextSize]="'text-xs lg:text-sm'"
                [selectedOption]="
                  module.externalAssigned[0] | moduleActiveAssignedUser
                "
                [disabled]="!canAssignModuleUser"
                (emitSelectedValue)="assignUserToModule(module, $event)"
              ></dominion-dropdown-search>
            </div>
          </div>

          <!-- SUBMODULES -->
          <div
            class="flex h-full flex-row items-center justify-end space-x-1 2xl:space-x-5"
          >
            @for (
              submodule of module.submodules;
              track submodule.submoduleId;
              let i = $index
            ) {
              <dominion-submodule-entry
                [submoduleRef]="submodule"
                [position]="i"
                [isInternalView]="internalView"
                [isSinglePhase]="module.submodules.length === 1"
                [canEditModuleDetails]="canEditModuleDetails"
                (dueDateSet)="
                  updateSubmoduleDetails(
                    {
                      field: 'dueDate',
                      value: $event.date,
                      moduleId: module._id,
                      submoduleId: submodule.submoduleId
                    },
                    $event.component
                  )
                "
                (availabilitySet)="
                  updateSubmoduleDetails(
                    {
                      field: 'isAvailable',
                      value: $event,
                      moduleId: module._id,
                      submoduleId: submodule.submoduleId
                    },
                    null
                  )
                "
              ></dominion-submodule-entry>
            }
          </div>

          <!-- MODULE ACTION BUTTON -->
          <div class="flex w-full flex-row items-center justify-center">
            <dominion-module-action-btn
              [isInternalView]="internalView"
              [percentageComplete]="50"
              [isAvailable]="
                module.submodules[0] ? module.submodules[0].isAvailable : false
              "
              (clicked)="goToModule(module)"
            ></dominion-module-action-btn>
          </div>
        </div>
      </div>
    }
  </div>
}

<!--  -->
<!--  -->
<!-- ADD MODULE MODAL -->
<dominion-modal #addModuleModal title="Add Module">
  <form
    body
    class="flex min-h-[450px] min-w-[400px] flex-col"
    [formGroup]="moduleForm"
    (ngSubmit)="addModule()"
  >
    <!-- SELECT MODULE TYPE -->
    <!-- Select Module Error -->
    <div
      *ngIf="
        moduleForm.get('moduleType')?.invalid &&
        moduleForm.get('moduleType')?.touched
      "
      class="flex flex-row"
    >
      <div class="flex-1">
        <div dominionStandardFormError>Please select a module type.</div>
      </div>
    </div>
    <!-- Select Module Label -->
    <div
      *ngIf="
        this.moduleForm.get('moduleType')?.valid ||
        this.moduleForm.get('moduleType')?.untouched
      "
      class="flex flex-row"
    >
      <div class="flex-1">
        <div dominionStandardFormLabel [required]="true">Module Type</div>
      </div>
    </div>
    <!-- Select Module Input -->
    <div class="flex flex-row">
      <div class="flex-1">
        <dominion-dropdown-search
          [options]="moduleOptions"
          [toggleText]="'Select a Module Type'"
          [selectedOption]="selectedModule"
          [enableCreateNew]="false"
          [searchable]="false"
          (emitClosed)="markSelectModuleAsTouched()"
          (emitSelectedValue)="selectModuleType($event)"
        ></dominion-dropdown-search>
      </div>
    </div>

    <!-- ENTER MODULE NAME -->
    <!-- Module Name Label -->
    <div
      *ngIf="moduleForm.get('name')?.valid || moduleForm.get('name')?.untouched"
      class="mt-6 flex flex-row"
    >
      <div class="flex-1">
        <div dominionStandardFormLabel>Module Name</div>
      </div>
    </div>
    <!-- Module Name Input -->
    <div class="flex flex-row">
      <div class="flex-1">
        <input
          #emailInput
          class="outline-dms-light-green w-full overflow-hidden rounded-sm border p-2 text-center text-sm placeholder:font-light placeholder:italic"
          type="text"
          formControlName="name"
          placeholder="Unique Module Name"
        />
      </div>
    </div>

    <!-- SUBMIT BUTTON -->
    <div class="mt-8 flex flex-row">
      <div dominionStandardFormCancelBtn (click)="cancel()" class="flex-1">
        Cancel
      </div>
      <div class="flex-1">
        <dominion-standard-form-submit
          type="submit"
          [disabled]="!moduleForm.valid"
          text="Add Module"
          [isLoading]="isAddingModule"
        ></dominion-standard-form-submit>
      </div>
    </div>
  </form>
</dominion-modal>
