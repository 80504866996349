import { DataInitializerBasic } from '../../core/core-module.interfaces';
import {
  Structures,
  TDataDefinition,
  TSubmoduleGroupMap,
} from '../../core/core-submodule.interfaces';

export type TPartsDefaultGroupIdentifiers =
  | 'groupPartsDefaultGeneral'
  | 'groupPartsDefaultGrossProfit'
  | 'groupPartsDefaultPrinting';

export type TPartsDefaultDataKeys =
  | 'binLocation'
  | 'coreChargeTax'
  | 'useNPNPriceCode'
  | 'defaultNPNPriceCode'
  | 'notifyMinInvoiceGP'
  | 'minimumInvoiceGP'
  | 'notifyMinRepairOrderGP'
  | 'minimumRepairOrderGP'
  | 'orderSourcePrompt'
  | 'partCostVisibility'
  | 'partQuantityAlert'
  | 'partsDefaultAccountCode'
  | 'partsDeskExpirationDays'
  | 'partsInvoiceBarcode'
  | 'usePartsInvoiceComment'
  | 'partsInvoiceComment'
  | 'partsInvoicePrintPrompt'
  | 'partsInvoiceSalespersonInfo'
  | 'partsOrderCommentType'
  | 'partsQuoteSalespersonInfo'
  | 'printSpecialOrderParts'
  | 'retailMarkup'
  | 'searchPartBase'
  | 'verifyWarrantyPartsReceived'
  | 'wholesaleMarkup';

const partsDefaultGroupMap = {
  moduleType: 'parts',
  submoduleType: 'default',
  discriminator: 'parts-default',
  groups: {
    groupPartsDefaultGeneral: [
      'partQuantityAlert',
      'binLocation',
      'searchPartBase',
      'useNPNPriceCode',
      'defaultNPNPriceCode',
      'orderSourcePrompt',
      'partCostVisibility',
      'partsOrderCommentType',
      'partsDefaultAccountCode',
      'verifyWarrantyPartsReceived',
      'coreChargeTax',
      'partsDeskExpirationDays',
      'wholesaleMarkup',
      'retailMarkup',
    ],
    groupPartsDefaultGrossProfit: [
      'notifyMinInvoiceGP',
      'minimumInvoiceGP',
      'notifyMinRepairOrderGP',
      'minimumRepairOrderGP',
    ],
    groupPartsDefaultPrinting: [
      'printSpecialOrderParts',
      'partsInvoicePrintPrompt',
      'partsInvoiceBarcode',
      'partsInvoiceSalespersonInfo',
      'usePartsInvoiceComment',
      'partsInvoiceComment',
      'partsQuoteSalespersonInfo',
    ],
  },
} satisfies TSubmoduleGroupMap<
  TPartsDefaultGroupIdentifiers,
  TPartsDefaultDataKeys
>;

export type TPartsDefaultGroupMap = typeof partsDefaultGroupMap;

export class PartsDefaultData
  implements TDataDefinition<TPartsDefaultDataKeys>
{
  partQuantityAlert = new DataInitializerBasic<
    'invoices' | 'orders' | 'all' | 'none'
  >();
  binLocation = new DataInitializerBasic<string>();
  searchPartBase = new DataInitializerBasic<'entire' | 'base'>();
  useNPNPriceCode = new DataInitializerBasic<'default' | 'manual'>();
  defaultNPNPriceCode = new DataInitializerBasic<string>();
  orderSourcePrompt = new DataInitializerBasic<boolean>();
  partCostVisibility = new DataInitializerBasic<'show' | 'hide'>();
  partsOrderCommentType = new DataInitializerBasic<
    | 'customer-name'
    | 'part-number'
    | 'name-number'
    | 'desc'
    | 'comment'
    | 'none'
  >();
  partsDefaultAccountCode = new DataInitializerBasic<string>();
  verifyWarrantyPartsReceived = new DataInitializerBasic<boolean>();
  coreChargeTax = new DataInitializerBasic<
    'non-refundable' | 'refundable' | 'never'
  >();
  partsDeskExpirationDays = new DataInitializerBasic<string>();
  wholesaleMarkup = new DataInitializerBasic<string>();
  retailMarkup = new DataInitializerBasic<string>();
  notifyMinInvoiceGP = new DataInitializerBasic<boolean>();
  minimumInvoiceGP = new DataInitializerBasic<string>();
  notifyMinRepairOrderGP = new DataInitializerBasic<boolean>();
  minimumRepairOrderGP = new DataInitializerBasic<string>();
  printSpecialOrderParts = new DataInitializerBasic<
    'ticket-separate' | 'customer-separate' | 'none'
  >();
  partsInvoicePrintPrompt = new DataInitializerBasic<
    'always' | 'not-printed' | 'modified' | 'never'
  >();
  partsInvoiceBarcode = new DataInitializerBasic<boolean>();
  partsInvoiceSalespersonInfo = new DataInitializerBasic<
    'name' | 'employee-number'
  >();
  usePartsInvoiceComment = new DataInitializerBasic<boolean>();
  partsInvoiceComment = new DataInitializerBasic<string>();
  partsQuoteSalespersonInfo = new DataInitializerBasic<
    'name' | 'employee-number'
  >();
}

export const partsDefaultStructure = {
  moduleType: 'parts',
  submoduleType: 'default',
  discriminator: 'parts-default',
  groups: {
    groupPartsDefaultGeneral: {
      groupLabel: 'General',
      groupUrl: 'groupPartsDefaultGeneral',
      groupType: 'standard',
      questions: {
        partQuantityAlert: {
          questionKey: 'partQuantityAlert',
          label: 'Part Quantity Alert',
          componentType: 'select-list',
          requirement: 'required',
          allowSkip: false,
          prompt: [
            {
              attributes: null,
              text: 'VUE should alert the user when there is an insufficient on-hand quantity (OHQ) of a part for which types of tickets?',
            },
          ],
          subprompt: [],
          options: [
            { value: 'invoices', label: 'Parts invoices only' },
            { value: 'orders', label: 'Repair orders only' },
            { value: 'all', label: 'All tickets (always alert)' },
            { value: 'none', label: 'No tickets (never alert)' },
          ],
          validation: [],
          dependencies: [],
        },
        binLocation: {
          questionKey: 'binLocation',
          label: 'Bin Location',
          componentType: 'text',
          requirement: 'required',
          allowSkip: false,
          prompt: [
            {
              attributes: null,
              text: 'When adding a new part to inventory, VUE should default to which bin location?',
            },
          ],
          subprompt: [],
          options: [],
          validation: [],
          dependencies: [],
        },
        searchPartBase: {
          questionKey: 'searchPartBase',
          label: 'Search Part Base',
          componentType: 'select-list',
          requirement: 'required',
          allowSkip: false,
          prompt: [
            {
              attributes: null,
              text: 'When searching for a part, should VUE search using the ',
            },
            {
              attributes: { bold: true },
              text: 'entire ',
            },
            {
              attributes: null,
              text: 'part number or the ',
            },
            {
              attributes: { bold: true },
              text: 'base ',
            },
            {
              attributes: null,
              text: 'part number?',
            },
          ],
          subprompt: [
            {
              attributes: null,
              text: 'Most dealers choose to search using only the base part number.\n\nUsing the entire part number makes it more likely that your desired part is included in the search results, but many irrelevant parts may also be included. Using the base part number reduces the number of irrelevant results, but might also exclude your desired part.',
            },
          ],
          options: [
            { value: 'entire', label: 'Entire part number' },
            { value: 'base', label: 'Base part number' },
          ],
          validation: [],
          dependencies: [],
        },
        useNPNPriceCode: {
          questionKey: 'useNPNPriceCode',
          label: 'Use NPN Price Code',
          componentType: 'select-list',
          requirement: 'required',
          allowSkip: false,
          prompt: [
            {
              attributes: null,
              text: 'When adding a non-OEM part, VUE should:',
            },
          ],
          subprompt: [],
          options: [
            { value: 'default', label: 'Apply a default price code.' },
            {
              value: 'manual',
              label: 'Require the user to manually set the markup.',
            },
          ],
          validation: [],
          dependencies: [
            {
              comparisonMethod: 'equals',
              comparisonValue: 'default',
              dependentKeys: ['defaultNPNPriceCode'],
            },
          ],
        },
        defaultNPNPriceCode: {
          questionKey: 'defaultNPNPriceCode',
          label: 'Default NPN Price Code',
          componentType: 'text',
          requirement: 'dependent',
          allowSkip: false,
          prompt: [
            {
              attributes: null,
              text: 'VUE should use which price code as the default for a non-OEM part?',
            },
          ],
          subprompt: [],
          options: [],
          validation: [],
          dependencies: [],
        },
        orderSourcePrompt: {
          questionKey: 'orderSourcePrompt',
          label: 'Order Source Prompt',
          componentType: 'select',
          requirement: 'required',
          allowSkip: false,
          prompt: [
            {
              attributes: null,
              text: "When adding a new part to inventory, should VUE require the user to also provide the part's order source?",
            },
          ],
          subprompt: [
            {
              attributes: null,
              text: 'Most dealers choose "Yes."\n\nChoosing "Yes" ensures that VUE captures the order source immediately when creating a new part. Choosing "No" means that a user will have to enter the order source information later if it is needed.',
            },
          ],
          options: [
            { value: true, label: 'Yes' },
            { value: false, label: 'No' },
          ],
          validation: [],
          dependencies: [],
        },
        partCostVisibility: {
          questionKey: 'partCostVisibility',
          label: 'Part Cost Visibility',
          componentType: 'select',
          requirement: 'required',
          allowSkip: false,
          prompt: [
            {
              attributes: null,
              text: 'When viewing part lines or inventory records, should VUE ',
            },
            {
              attributes: { bold: true },
              text: 'show ',
            },
            {
              attributes: null,
              text: 'or ',
            },
            {
              attributes: { bold: true },
              text: 'hide ',
            },
            {
              attributes: null,
              text: 'the part cost by default?',
            },
          ],
          subprompt: [
            {
              attributes: null,
              text: 'Many dealers choose to hide part cost by default so that retail customers do not see the cost.\n\nIf you select "Hide," authorized users will still be able to show the part cost manually. Regardless of what you select here, users who do not have authorization to see part cost will not be able to see it.',
            },
          ],
          options: [
            { value: 'show', label: 'Show' },
            { value: 'hide', label: 'Hide' },
          ],
          validation: [],
          dependencies: [],
        },
        partsOrderCommentType: {
          questionKey: 'partsOrderCommentType',
          label: 'Parts Order Comment Type',
          componentType: 'select-list',
          requirement: 'required',
          allowSkip: false,
          prompt: [
            {
              attributes: null,
              text: 'When adding a part to a parts order, VUE should add which of the following to the comment field:',
            },
          ],
          subprompt: [],
          options: [
            { value: 'customer-name', label: "The customer's name." },
            { value: 'part-number', label: 'The part number.' },
            {
              value: 'name-number',
              label: "The customer's name and the part number.",
            },
            { value: 'desc', label: 'The part description.' },
            { value: 'comment', label: 'The comment from the special order.' },
            { value: 'none', label: 'Do not add a comment automatically.' },
          ],
          validation: [],
          dependencies: [],
        },
        partsDefaultAccountCode: {
          questionKey: 'partsDefaultAccountCode',
          label: 'Parts Default Account Code',
          componentType: 'text',
          requirement: 'required',
          allowSkip: false,
          prompt: [
            {
              attributes: null,
              text: 'When creating a parts invoice, VUE should default to assigning which account code to the invoice?',
            },
          ],
          subprompt: [],
          options: [],
          validation: [],
          dependencies: [],
        },
        verifyWarrantyPartsReceived: {
          questionKey: 'verifyWarrantyPartsReceived',
          label: 'Verify Warranty Parts Received',
          componentType: 'select',
          requirement: 'required',
          allowSkip: false,
          prompt: [
            {
              attributes: null,
              text: 'When a repair order (RO) contains a warranty operation with warranty parts, should VUE require the parts department to confirm that warranty parts have been received before the warranty operation can be performed?',
            },
          ],
          subprompt: [],
          options: [
            { value: true, label: 'Yes' },
            { value: false, label: 'No' },
          ],
          validation: [],
          dependencies: [],
        },
        coreChargeTax: {
          questionKey: 'coreChargeTax',
          label: 'Core Charge Tax',
          componentType: 'select-list',
          requirement: 'required',
          allowSkip: false,
          prompt: [
            {
              attributes: null,
              text: "Based on my state's requirements, VUE should:",
            },
          ],
          subprompt: [
            {
              attributes: null,
              text: 'If you choose a non-refundable sales tax on the core charge, then the customer will always pay sales tax on the core, whether they return a core or not. If you choose a refundable sales tax on the core charge, then the customer will receive a refund for the sales tax they paid on the core charge if they return the core.',
            },
          ],
          options: [
            {
              value: 'non-refundable',
              label: 'Charge a non-refundable sales tax on the core charge.',
            },
            {
              value: 'refundable',
              label: 'Charge a refundable sales tax on the core charge.',
            },
            {
              value: 'never',
              label: 'Never charge sales tax on the core charge.',
            },
          ],
          validation: [],
          dependencies: [],
        },
        partsDeskExpirationDays: {
          questionKey: 'partsDeskExpirationDays',
          label: 'Parts Desk Expiration Days',
          componentType: 'text',
          requirement: 'required',
          allowSkip: false,
          prompt: [
            {
              attributes: null,
              text: 'When saving a parts desk, VUE should set the parts desk to expire automatically after how many days?',
            },
          ],
          subprompt: [
            {
              attributes: null,
              text: 'Most dealers choose to use 30 days.',
            },
          ],
          options: [],
          validation: [],
          dependencies: [],
        },
        wholesaleMarkup: {
          questionKey: 'wholesaleMarkup',
          label: 'Wholesale Markup',
          componentType: 'text',
          requirement: 'required',
          allowSkip: false,
          prompt: [
            {
              attributes: null,
              text: 'When a part does not have a pre-defined wholesale price, VUE should automatically mark up the cost by what percentage to determine the wholesale price?',
            },
          ],
          subprompt: [
            {
              attributes: null,
              text: 'This value is simply a default and can be edited by the user on a case-by-case basis. This setting only applies if a part does not have its own wholesale price in VUE.',
            },
          ],
          options: [],
          validation: [],
          dependencies: [],
        },
        retailMarkup: {
          questionKey: 'retailMarkup',
          label: 'Retail Markup',
          componentType: 'text',
          requirement: 'required',
          allowSkip: false,
          prompt: [
            {
              attributes: null,
              text: 'When a part does not have a pre-defined retail price, VUE should automatically mark up the cost by what percentage to determine the retail price?',
            },
          ],
          subprompt: [
            {
              attributes: null,
              text: 'This value is simply a default and can be edited by the user on a case-by-case basis. This setting only applies if a part does not have its own retail price in VUE.',
            },
          ],
          options: [],
          validation: [],
          dependencies: [],
        },
      },
    },
    groupPartsDefaultGrossProfit: {
      groupLabel: 'Gross Profit',
      groupUrl: 'groupPartsDefaultGrossProfit',
      groupType: 'standard',
      questions: {
        notifyMinInvoiceGP: {
          questionKey: 'notifyMinInvoiceGP',
          label: 'Notify Min Invoice GP',
          componentType: 'select',
          requirement: 'required',
          allowSkip: false,
          prompt: [
            {
              attributes: null,
              text: 'When saving a parts invoice, should VUE notify the user if the gross profit percentage falls below a minimum threshold?',
            },
          ],
          subprompt: [],
          options: [
            { value: true, label: 'Yes' },
            { value: false, label: 'No' },
          ],
          validation: [],
          dependencies: [
            {
              comparisonMethod: 'equals',
              comparisonValue: true,
              dependentKeys: ['minimumInvoiceGP'],
            },
          ],
        },
        minimumInvoiceGP: {
          questionKey: 'minimumInvoiceGP',
          label: 'Minimum Invoice GP',
          componentType: 'text',
          requirement: 'dependent',
          allowSkip: false,
          prompt: [
            {
              attributes: null,
              text: 'VUE should notify the user when the gross profit percentage on a parts invoice falls below:',
            },
          ],
          subprompt: [],
          options: [],
          validation: [],
          dependencies: [],
        },
        notifyMinRepairOrderGP: {
          questionKey: 'notifyMinRepairOrderGP',
          label: 'Notify Min Repair Order GP',
          componentType: 'select',
          requirement: 'required',
          allowSkip: false,
          prompt: [
            {
              attributes: null,
              text: 'When saving a repair order (RO), should VUE notify the user if the gross profit percentage falls below a minimum threshold?',
            },
          ],
          subprompt: [],
          options: [
            { value: true, label: 'Yes' },
            { value: false, label: 'No' },
          ],
          validation: [],
          dependencies: [
            {
              comparisonMethod: 'equals',
              comparisonValue: true,
              dependentKeys: ['minimumRepairOrderGP'],
            },
          ],
        },
        minimumRepairOrderGP: {
          questionKey: 'minimumRepairOrderGP',
          label: 'Minimum Repair Order GP',
          componentType: 'text',
          requirement: 'dependent',
          allowSkip: false,
          prompt: [
            {
              attributes: null,
              text: 'VUE should notify the user when the gross profit percentage on a repair order (RO) falls below:',
            },
          ],
          subprompt: [],
          options: [],
          validation: [],
          dependencies: [],
        },
      },
    },
    groupPartsDefaultPrinting: {
      groupLabel: 'Printing',
      groupUrl: 'groupPartsDefaultPrinting',
      groupType: 'standard',
      questions: {
        printSpecialOrderParts: {
          questionKey: 'printSpecialOrderParts',
          label: 'Print Special Order Parts',
          componentType: 'select-list',
          requirement: 'required',
          allowSkip: false,
          prompt: [
            {
              attributes: null,
              text: 'When creating a parts invoice that contains special order parts, VUE should:',
            },
          ],
          subprompt: [],
          options: [
            {
              value: 'ticket-separate',
              label:
                'List all special order parts for the ticket in a separate section.',
            },
            {
              value: 'customer-separate',
              label:
                'List all special order parts for the customer in a separate section.',
            },
            {
              value: 'none',
              label:
                'List all special order parts with all standard parts (no separation).',
            },
          ],
          validation: [],
          dependencies: [],
        },
        partsInvoicePrintPrompt: {
          questionKey: 'partsInvoicePrintPrompt',
          label: 'Parts Invoice Print Prompt',
          componentType: 'select-list',
          requirement: 'required',
          allowSkip: false,
          prompt: [
            {
              attributes: null,
              text: 'When closing a parts invoice, VUE should:',
            },
          ],
          subprompt: [],
          options: [
            {
              value: 'always',
              label: 'Always prompt the user to print the invoice.',
            },
            {
              value: 'not-printed',
              label:
                'Prompt the user to print if the invoice has not been printed previously.',
            },
            {
              value: 'modified',
              label:
                'Prompt the user to print if the invoice was modified since the last print.',
            },
            { value: 'never', label: 'Never prompt to print' },
          ],
          validation: [],
          dependencies: [],
        },
        partsInvoiceBarcode: {
          questionKey: 'partsInvoiceBarcode',
          label: 'Parts Invoice Barcode',
          componentType: 'select',
          requirement: 'required',
          allowSkip: false,
          prompt: [
            {
              attributes: null,
              text: 'When printing a parts invoice, should VUE include a barcode on the invoice?',
            },
          ],
          subprompt: [],
          options: [
            { value: true, label: 'Yes' },
            { value: false, label: 'No' },
          ],
          validation: [],
          dependencies: [],
        },
        partsInvoiceSalespersonInfo: {
          questionKey: 'partsInvoiceSalespersonInfo',
          label: 'Parts Invoice Salesperson Info',
          componentType: 'select-list',
          requirement: 'required',
          allowSkip: false,
          prompt: [
            {
              attributes: null,
              text: "When generating a parts invoice, should VUE include the salesperson's ",
            },
            {
              attributes: { bold: true },
              text: 'name ',
            },
            {
              attributes: null,
              text: 'or ',
            },
            {
              attributes: { bold: true },
              text: 'employee number ',
            },
            {
              attributes: null,
              text: 'on the invoice?',
            },
          ],
          subprompt: [],
          options: [
            { value: 'name', label: "Salesperson's name" },
            { value: 'employee-number', label: 'Employee number' },
          ],
          validation: [],
          dependencies: [],
        },
        usePartsInvoiceComment: {
          questionKey: 'usePartsInvoiceComment',
          label: 'Use Parts Invoice Comment',
          componentType: 'select',
          requirement: 'required',
          allowSkip: false,
          prompt: [
            {
              attributes: null,
              text: 'When generating a parts invoice, should VUE automatically include a standard customer-facing comment?',
            },
          ],
          subprompt: [
            {
              attributes: null,
              text: 'For instance, some dealers choose to populate the comment field with: "Thank you for your business!"',
            },
          ],
          options: [
            { value: true, label: 'Yes' },
            { value: false, label: 'No' },
          ],
          validation: [],
          dependencies: [
            {
              comparisonMethod: 'equals',
              comparisonValue: true,
              dependentKeys: ['partsInvoiceComment'],
            },
          ],
        },
        partsInvoiceComment: {
          questionKey: 'partsInvoiceComment',
          label: 'Parts Invoice Comment',
          componentType: 'text',
          requirement: 'dependent',
          allowSkip: false,
          prompt: [
            {
              attributes: null,
              text: 'VUE should automatically populate the customer-facing comment field on the parts invoice to say:',
            },
          ],
          subprompt: [],
          options: [],
          validation: [],
          dependencies: [],
        },
        partsQuoteSalespersonInfo: {
          questionKey: 'partsQuoteSalespersonInfo',
          label: 'Parts Quote Salesperson Info',
          componentType: 'select-list',
          requirement: 'required',
          allowSkip: false,
          prompt: [
            {
              attributes: null,
              text: "When generating a parts quote, should VUE include the salesperson's ",
            },
            {
              attributes: { bold: true },
              text: 'name ',
            },
            {
              attributes: null,
              text: 'or ',
            },
            {
              attributes: { bold: true },
              text: 'employee number ',
            },
            {
              attributes: null,
              text: 'on the quote?',
            },
          ],
          subprompt: [],
          options: [
            { value: 'name', label: "Salesperson's name" },
            { value: 'employee-number', label: 'Employee number' },
          ],
          validation: [],
          dependencies: [],
        },
      },
    },
  },
} satisfies Structures['parts-default'];
