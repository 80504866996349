<div
  class="relative flex flex-row items-center rounded-full border border-gray-200 bg-white p-1 text-gray-700 group-hover/module:shadow-md md:rounded-l-[20px] md:rounded-r-[6px]"
  [ngClass]="{
    available: submoduleRef.isAvailable,
    complete: submoduleRef.completion.complete === submoduleRef.completion.total
  }"
>
  <!-- STATUS BAR -->
  <div
    class="absolute -top-2 left-0 z-10 hidden h-1 w-full flex-row group-hover:flex group-hover/module:[&.incomplete_#status-bar]:animate-pulse"
    [ngClass]="{
      incomplete:
        submoduleRef.isAvailable &&
        submoduleRef.completion.complete < submoduleRef.completion.total
    }"
  >
    <dominion-completion-status-bar
      [percentage]="submoduleRef | submoduleStatus"
      [rounded]="'rounded-none'"
      [bgColor]="'bg-gray-100 group-hover/module:bg-gray-200'"
      [completionColor]="'bg-dms-mid-green'"
    ></dominion-completion-status-bar>
  </div>

  <!-- PHASE -->
  <div
    [title]="isSinglePhase ? 'Single Phase' : 'Phase ' + (position + 1)"
    class="absolute -bottom-0 left-[50%] z-10 -translate-x-1/2 translate-y-2/3 flex-row rounded-full border border-gray-200 bg-white px-2 text-center text-[11px] font-light uppercase text-gray-800"
  >
    <span class="hidden whitespace-nowrap md:inline-block">{{
      isSinglePhase ? 'Single Phase' : 'Phase ' + (position + 1)
    }}</span>
    <span class="md:hidden">{{ position + 1 }}</span>
  </div>

  <!-- STATUS CIRCLE -->
  <div class="h-8 w-8 md:h-6 md:w-6 lg:h-8 lg:w-8">
    <dominion-module-status-circle
      [isLocked]="!submoduleRef.isAvailable"
      [stage]="position + 1"
      [completion]="submoduleRef | submoduleStatus"
      [canEditModuleDetails]="canEditModuleDetails"
      (click)="setAvailability()"
    ></dominion-module-status-circle>
  </div>

  <!-- DUE DATE -->
  <div
    class="hidden flex-1 flex-row items-center justify-center space-x-1 px-[1px] text-xs md:flex lg:px-1 xl:text-sm"
  >
    @if (submoduleRef.dueDate) {
      <span class="hidden text-xs uppercase text-gray-400 lg:inline-block">
        Due
      </span>
    }
    <dominion-in-situ-text
      #dueDateInput
      type="date"
      [initialValue]="submoduleRef.dueDate | date: 'MM/dd/yyy'"
      [isDisabled]="!canEditModuleDetails"
      (saved)="setDueDate($event, dueDateInput)"
      [defaultValue]="isInternalView ? 'Due Date' : 'Date Pending'"
      formErrMsg="Please enter a valid date (MM/DD/YYYY)"
      [staticClassConfig]="{
        hoverBorderColor: 'hover:border-gray-500'
      }"
    ></dominion-in-situ-text>
  </div>
</div>
