import { Pipe, PipeTransform } from '@angular/core';
import { SubmoduleType, TSubmoduleDiscriminators } from '@dominion/interfaces';

@Pipe({
  name: 'submodulePhase',
  standalone: true,
})
export class SubmodulePhasePipe implements PipeTransform {
  transform(value: TSubmoduleDiscriminators): string {
    const phase = value.split('-')[1];
    switch (phase) {
      case 'preliminary':
        return 'Phase 1';
      case 'maintenance':
        return 'Phase 2';
      case 'default':
        return 'Phase 3';
      default:
        return value;
    }
  }
}
