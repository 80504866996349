import { Directive, HostBinding, Input, OnInit } from '@angular/core';

@Directive({
  selector: '[dominionDiscoveryBtn]',
  standalone: true,
})
export class DiscoveryBtnDirective implements OnInit {
  @HostBinding() class =
    'basis-1/2 p-2 border border-gray-300 rounded cursor-pointer hover:ring ring-offset-1 ring-dms-light-green [&.selected]:bg-dms-light-green/35 [&.selected]:border-dms-light-green  aria-selected:bg-dms-light-green/35 aria-selected:border-dms-light-green';

  @Input() options: Partial<typeof this.defaultOptions> = {};

  defaultOptions = {
    textSize: 'text-base',
    textAlign: 'text-center',
  };

  constructor() {}

  ngOnInit(): void {
    const defaults = Object.assign({}, this.defaultOptions);
    const mergedClasses = Object.assign(defaults, this.options);
    const values = Object.values(mergedClasses);
    const classString = values.join(' ');
    this.class = `${this.class} ${classString}`;
  }
}
