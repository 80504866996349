import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouteReuseStrategy, RouterModule, Routes } from '@angular/router';
import { RegisterComponent } from '../auth/register/register.component';
import { ConfirmEmailComponent } from '../auth/confirm-email/confirm-email.component';
import { RegistrationSuccessComponent } from '../auth/registration-success/registration-success.component';
import { LoginComponent } from '../auth/login/login.component';
import { ForgotPasswordComponent } from '../auth/forgot-password/forgot-password.component';
import { CoreRootComponent } from '../core-root/core-root.component';
import { AdminDashboardComponent } from '../admin/admin-dashboard/admin-dashboard.component';
import { InternalGuard } from '../auth/internal.guard';
import { ResetPasswordComponent } from '../auth/reset-password/reset-password.component';
import { ManageAccountComponent } from '../manage-account/manage-account.component';
import { InternalComponent } from '../admin/admin/admin.component';
import { ExternalGuard } from '../auth/external.guard';
import { AdminCompaniesComponent } from '../admin/admin-companies/admin-companies.component';
import { AdminUsersComponent } from '../admin/admin-users/admin-users.component';
import { CreateCompanyComponent } from '../admin/admin-companies/create-company/create-company.component';
import { CompanyDetailComponent } from '../company/company-detail/company-detail.component';
import { InviteRegistrationComponent } from '../auth/invite-registration/invite-registration.component';
import { UserDetailComponent } from '../user/user-detail/user-detail.component';
import { DiscoveryRootComponent } from '../discovery-root/discovery-root.component';
import { DiscoveryModuleDashboardComponent } from '../discovery-module-dashboard/discovery-module-dashboard.component';
import { AdminQuestionsComponent } from '../admin/admin-questions/admin-questions.component';
import { AdminSupportComponent } from '../admin/admin-support/admin-support.component';
import { VendorsComponent } from '../vendors/vendors.component';
import { CompanyVendorsComponent } from '../modules/company-vendors/company-vendors.component';
import { AnimationTestingComponent } from '../shared/animation-testing/animation-testing.component';
import { CompanyDocumentsComponent } from '../company-documents/company-documents.component';
import { GroupContainerComponent } from '../group-container/group-container.component';
import { CustomRouteReuseStrategy } from './route-reuse.strategy';

const routes: Routes = [
  {
    path: 'testing',
    component: AnimationTestingComponent,
  },
  {
    path: 'register',
    component: RegisterComponent,
  },
  {
    path: 'confirm-email/:id/:code',
    component: ConfirmEmailComponent,
  },
  {
    path: 'registration-success',
    component: RegistrationSuccessComponent,
  },
  {
    path: 'invite-registration/:id',
    component: InviteRegistrationComponent,
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent,
  },
  {
    path: 'reset-password/:_id/:token',
    component: ResetPasswordComponent,
  },
  {
    path: 'core',
    component: CoreRootComponent,
    children: [
      {
        path: 'admin',
        component: InternalComponent,
        canActivate: [InternalGuard],
        canActivateChild: [InternalGuard],
        children: [
          {
            path: 'dashboard',
            component: AdminDashboardComponent,
          },
          {
            path: 'vendors',
            component: VendorsComponent,
          },
          {
            path: 'manage-account',
            component: ManageAccountComponent,
          },
          {
            path: 'companies',
            component: AdminCompaniesComponent,
          },
          {
            path: 'companies/create-company',
            component: CreateCompanyComponent,
          },
          {
            path: 'companies/:companyid/detail',
            component: CompanyDetailComponent,
          },
          {
            path: 'users',
            component: AdminUsersComponent,
          },
          {
            path: 'users/:userid',
            component: UserDetailComponent,
          },
          {
            path: 'questions',
            component: AdminQuestionsComponent,
          },
          {
            path: 'support',
            component: AdminSupportComponent,
          },
          {
            path: '**',
            redirectTo: 'dashboard',
          },
        ],
      },
      {
        path: ':companyid',
        canActivate: [ExternalGuard],
        canActivateChild: [ExternalGuard],
        children: [
          {
            path: 'dashboard',
            component: CompanyDetailComponent,
          },
          {
            path: 'manage-account',
            component: ManageAccountComponent,
          },
          {
            path: 'users/:userid',
            component: UserDetailComponent,
          },
          {
            path: 'vendors',
            component: CompanyVendorsComponent,
          },
          {
            path: 'documents',
            component: CompanyDocumentsComponent,
          },
          {
            path: 'module',
            component: DiscoveryRootComponent,
            children: [
              {
                path: ':moduleid',
                component: DiscoveryModuleDashboardComponent,
              },
              {
                path: ':moduleid/:discriminator/:submoduleid/:groupUrl',
                component: GroupContainerComponent,
              },
            ],
          },
          {
            path: '',
            pathMatch: 'full',
            redirectTo: 'dashboard',
          },
          {
            path: '**',
            redirectTo: 'core',
          },
        ],
      },
      {
        path: '**',
        redirectTo: '',
      },
    ],
  },
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'login',
  },
  {
    path: '**',
    pathMatch: 'full',
    redirectTo: 'login',
  },
];

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forRoot(routes, {
      paramsInheritanceStrategy: 'always',
      bindToComponentInputs: true,
    }),
  ],
  providers: [
    {
      provide: RouteReuseStrategy,
      useClass: CustomRouteReuseStrategy,
    },
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
