import {
  Component,
  HostBinding,
  OnInit,
  computed,
  signal,
} from '@angular/core';
import {
  ActivatedRoute,
  Router,
  RouterLink,
  RouterOutlet,
} from '@angular/router';
import { QuestionSearchComponent } from './question-search/question-search.component';
import { QuestionListComponent } from './question-list/question-list.component';
import { SharedModule } from '../../shared/shared.module';
import { ModuleTypePipe } from '../../shared/pipes/module-type.pipe';
import {
  CoreSubmoduleVisual,
  ModuleType,
  SubmoduleType,
  accountingPreliminaryStructure,
  accountingMaintenanceStructure,
  partsPreliminaryStructure,
  partsMaintenanceStructure,
  payrollPreliminaryStructure,
  payrollMaintenanceStructure,
  servicePreliminaryStructure,
  serviceMaintenanceStructure,
  fiPreliminaryStructure,
  salesPreliminaryStructure,
  salesMaintenanceStructure,
  infraPreliminaryStructure,
  TGenericGroup,
  CoreQuestionVisual,
} from '@dominion/interfaces';
import { accountingDefaultStructure } from 'libs/interfaces/src/lib/modules/accounting/default/accounting-default-data.interfaces';
import { partsDefaultStructure } from 'libs/interfaces/src/lib/modules/parts/default/parts-default-data.interfaces';
import { serviceDefaultStructure } from 'libs/interfaces/src/lib/modules/service/default/service-default-data.interfaces';
import { salesDefaultStructure } from 'libs/interfaces/src/lib/modules/sales/default/sales-default-data.interfaces';

export type SearchableQuestion = {
  module: ModuleType;
  submodule: SubmoduleType;
  groupKey: string;
  parentKey: string;
  questionKey: string;
  questionLabel: string;
  questionPrompt: string;
};

@Component({
  selector: 'dominion-internal-questions',
  standalone: true,
  imports: [
    RouterLink,
    RouterOutlet,
    QuestionSearchComponent,
    QuestionListComponent,
    SharedModule,
    ModuleTypePipe,
  ],
  templateUrl: './admin-questions.component.html',
  styleUrl: './admin-questions.component.css',
})
export class AdminQuestionsComponent implements OnInit {
  @HostBinding('class') class = 'flex w-full flex-col h-full flex-1';

  groups = signal<Record<SubmoduleType, CoreSubmoduleVisual['groups']>>({
    preliminary: [] as CoreSubmoduleVisual['groups'],
    maintenance: [] as CoreSubmoduleVisual['groups'],
    default: [],
  });

  selectedModule = signal('accounting');
  selectedSubmodule = signal<SubmoduleType>('preliminary');
  selectedGroupKey = signal(
    Object.keys(accountingPreliminaryStructure.groups)[0],
  );
  selectedGroup = computed(() =>
    this.groups()[this.selectedSubmodule()].find(
      (group) => group.groupKey === this.selectedGroupKey(),
    ),
  );
  expandedQuestionKey = signal('');
  selectedQuestionKey = signal('');
  selectedQuestion = computed(
    () =>
      this.selectedGroup()?.questions.find(
        (question) => question.key === this.selectedQuestionKey(),
      ),
  );

  moduleTypes: ModuleType[] = [
    'accounting',
    'parts',
    'payroll',
    'service',
    'fi',
    'sales',
    'infrastructure',
  ];
  submoduleTypes: SubmoduleType[] = ['preliminary', 'maintenance', 'default'];

  moduleStructureMap = {
    accounting: {
      preliminary: accountingPreliminaryStructure.groups,
      maintenance: accountingMaintenanceStructure.groups,
      default: accountingDefaultStructure.groups,
    },
    parts: {
      preliminary: partsPreliminaryStructure.groups,
      maintenance: partsMaintenanceStructure.groups,
      default: partsDefaultStructure.groups,
    },
    payroll: {
      preliminary: payrollPreliminaryStructure.groups,
      maintenance: payrollMaintenanceStructure.groups,
    },
    service: {
      preliminary: servicePreliminaryStructure.groups,
      maintenance: serviceMaintenanceStructure.groups,
      default: serviceDefaultStructure.groups,
    },
    fi: {
      preliminary: fiPreliminaryStructure.groups,
    },
    sales: {
      preliminary: salesPreliminaryStructure.groups,
      maintenance: salesMaintenanceStructure.groups,
      default: salesDefaultStructure.groups,
    },
    infrastructure: {
      preliminary: infraPreliminaryStructure.groups,
    },
  };

  extractQuestionsFromStructure(structure: {
    moduleType: ModuleType;
    submoduleType: SubmoduleType;
    groups: Record<string, TGenericGroup<any>>;
  }): SearchableQuestion[] {
    let nearestParentKey = '';

    return Object.entries(structure.groups).flatMap(([_, group]) =>
      Object.entries(group.questions).map(([key, question]) => {
        if (question.requirement !== 'dependent') {
          nearestParentKey = key;
        }
        return {
          module: structure.moduleType,
          submodule: structure.submoduleType,
          groupKey: group.groupUrl,
          parentKey: nearestParentKey,
          questionKey: key,
          questionLabel: question.label ?? '',
          questionPrompt:
            question.prompt.map(({ text }) => text).join('\n') ?? '',
        };
      }),
    );
  }

  searchableQuestions = [
    ...this.extractQuestionsFromStructure(accountingPreliminaryStructure),
    ...this.extractQuestionsFromStructure(accountingMaintenanceStructure),
    ...this.extractQuestionsFromStructure(partsPreliminaryStructure),
    ...this.extractQuestionsFromStructure(partsMaintenanceStructure),
    ...this.extractQuestionsFromStructure(payrollPreliminaryStructure),
    ...this.extractQuestionsFromStructure(payrollMaintenanceStructure),
    ...this.extractQuestionsFromStructure(servicePreliminaryStructure),
    ...this.extractQuestionsFromStructure(serviceMaintenanceStructure),
    ...this.extractQuestionsFromStructure(fiPreliminaryStructure),
    ...this.extractQuestionsFromStructure(salesPreliminaryStructure),
    ...this.extractQuestionsFromStructure(salesMaintenanceStructure),
    ...this.extractQuestionsFromStructure(infraPreliminaryStructure),
    ...this.extractQuestionsFromStructure(accountingDefaultStructure),
    ...this.extractQuestionsFromStructure(partsDefaultStructure),
    ...this.extractQuestionsFromStructure(serviceDefaultStructure),
    ...this.extractQuestionsFromStructure(salesDefaultStructure),
  ];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
  ) {}

  isValueOfType<T>(value: unknown, allowedValues: T[]): value is T {
    if (!value) {
      return false;
    }
    return allowedValues.includes(value as T);
  }

  ngOnInit() {
    this.route.queryParamMap.subscribe((params) => {
      const moduleParam = params.get('module');
      if (this.isValueOfType(moduleParam, this.moduleTypes)) {
        this.selectedModule.set(moduleParam ?? 'accounting');
      }

      const submoduleParam = params.get('submodule');
      if (this.isValueOfType(submoduleParam, this.submoduleTypes)) {
        this.selectedSubmodule.set(submoduleParam ?? 'preliminary');
      }

      this.selectedGroupKey.set(params.get('group') ?? this.selectedGroupKey());
      this.selectedQuestionKey.set(params.get('question') ?? '');
      this.expandedQuestionKey.set(this.route.snapshot.fragment ?? '');

      this.updateGroups();
    });
  }

  selectModule(module: ModuleType) {
    this.selectedModule.set(module);
    this.updateGroups();
    this.router.navigate([], {
      queryParams: {
        module,
        submodule: 'preliminary',
        group: this.groups().preliminary[0]?.groupKey ?? '',
      },
    });
  }

  selectSubmoduleGroup(submodule: SubmoduleType, group: string) {
    this.router.navigate([], {
      queryParams: {
        module: this.selectedModule(),
        submodule,
        group,
      },
    });
  }

  updateGroups() {
    const moduleData =
      this.moduleStructureMap[
        this.selectedModule() as keyof typeof this.moduleStructureMap
      ];
    this.groups.set({
      preliminary:
        'preliminary' in moduleData
          ? this.transformGroups(moduleData.preliminary)
          : [],
      maintenance:
        'maintenance' in moduleData
          ? this.transformGroups(moduleData.maintenance)
          : [],
      default:
        'default' in moduleData ? this.transformGroups(moduleData.default) : [],
    });
  }

  transformGroups(
    groups: Record<string, TGenericGroup<any>>,
  ): CoreSubmoduleVisual['groups'] {
    const processedKeys = new Set<string>();

    function transformQuestion(
      questionKey: string,
      group: TGenericGroup<any>,
    ): CoreQuestionVisual {
      const question = group.questions[questionKey];
      const dependencies = question.dependencies.map((dep) => ({
        method: dep.comparisonMethod,
        value: dep.comparisonValue,
        questions: dep.dependentKeys.map((depKey) => {
          processedKeys.add(depKey);
          return transformQuestion(depKey, group);
        }),
      }));

      return {
        key: questionKey,
        label: question.label,
        prompt: question.prompt ?? '',
        subprompt: question.subprompt ?? [],
        requirement: question.requirement,
        componentType: question.componentType,
        options: question.options,
        validation: question.validation,
        dependencies,
      };
    }

    return Object.entries(groups).map(([groupKey, group]) => {
      const questions = Object.entries(group.questions).reduce(
        (acc, [questionKey]) => {
          if (!processedKeys.has(questionKey)) {
            const transformedQuestion = transformQuestion(questionKey, group);
            acc.push(transformedQuestion);
            processedKeys.add(questionKey);
          }
          return acc;
        },
        [] as CoreQuestionVisual[],
      );

      return {
        groupKey,
        groupLabel: group.groupLabel,
        groupType: group.groupType,
        questions,
      };
    });
  }
}
