import {
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ICoreSubmoduleReference } from '@dominion/interfaces';
import { ModuleStatusCircleComponent } from '../../shared/module-status-circle/module-status-circle.component';
import { InSituTextComponent } from '../../shared/inputs/in-situ-text/in-situ-text.component';
import { SubmoduleStatusPipe } from '../company-project/submodule-status.pipe';
import { CompletionStatusBarComponent } from '../../shared/completion-status-bar/completion-status-bar.component';

@Component({
  selector: 'dominion-submodule-entry',
  standalone: true,
  imports: [
    CommonModule,
    ModuleStatusCircleComponent,
    InSituTextComponent,
    SubmoduleStatusPipe,
    CompletionStatusBarComponent,
  ],
  templateUrl: './submodule-entry.component.html',
  styleUrls: ['./submodule-entry.component.css'],
})
export class SubmoduleEntryComponent {
  @HostBinding() class = 'md:w-1/3';

  @Input({ required: true }) submoduleRef: ICoreSubmoduleReference;
  @Input({ required: true }) position: number;
  @Input({ required: true }) isInternalView = false;
  @Input({ required: true }) canEditModuleDetails = false;
  @Input({ required: true }) isSinglePhase = false;

  @Output() dueDateSet = new EventEmitter<{
    date: Date;
    component: InSituTextComponent;
  }>();

  @Output() availabilitySet = new EventEmitter<boolean>();

  setAvailability() {
    if (this.canEditModuleDetails) {
      this.availabilitySet.emit(!this.submoduleRef.isAvailable);
    }
  }

  setDueDate(date: unknown, component: InSituTextComponent) {
    this.dueDateSet.emit({ date: date as Date, component });
  }
}
