import { Pipe, PipeTransform } from '@angular/core';
import { TGenericQStruct } from '@dominion/interfaces';

@Pipe({
  name: 'questionPipe',
  standalone: true,
})
export class QuestionPipe implements PipeTransform {
  transform(value: {
    [key: string]: TGenericQStruct<string>;
  }): TGenericQStruct<string>[] {
    return Object.values(value);
  }
}
