import { Component, HostBinding, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PopoverHostDirective } from '../directives/popover-host.directive';
import { PopoverDirective } from '../directives/popover.directive';
import { PopoverTargetDirective } from '../directives/popover-target.directive';

@Component({
  selector: 'dominion-completion-status-bar',
  standalone: true,
  imports: [
    CommonModule,
    PopoverDirective,
    PopoverHostDirective,
    PopoverTargetDirective,
  ],
  templateUrl: './completion-status-bar.component.html',
  styleUrls: ['./completion-status-bar.component.css'],
})
export class CompletionStatusBarComponent {
  @HostBinding() class = 'flex flex-1 flex-row';

  @Input({ required: true, transform: round }) percentage: number;
  @Input() rounded: string = 'rounded';
  @Input() bgColor: string = 'bg-gray-100';
  @Input() completionColor: string = 'bg-green-600';

  constructor() {}
}

function round(value: number) {
  return Math.round(value);
}
