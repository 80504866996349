import { Injectable } from '@angular/core';
import { AuthenticationService } from '../auth/authentication.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import {
  IAuthorizeVendorsDto,
  ICompanyCreateDto,
  ICompanyUpdateDto,
  ICoreModuleAddUserDto,
  ICoreModuleCreate,
  ICoreModuleUpdate,
  IFileDeleteDto,
  IFileGetDto,
  IGetModuleDto,
  IGetSubmoduleDataDto,
  IGetSubmoduleFieldsBase,
  ICompanyReadBrief,
  ICompanyReadBriefMeta,
  ICompanyReadExternalBrief,
  ICompanyReadFull,
  ICompanyVendors,
  ICoreModule,
  ICoreModuleReadBrief,
  ICoreSubmodule,
  IDominionAssignables,
  IFileUploadReference,
  IInvitedUserCreate,
  ISubmoduleResponse,
  ISubmoduleUpdate,
  TSubmoduleDiscriminators,
  UserExternalAssignments,
  IUserExternalAuthDto,
  UserInternalAssignments,
  VendorAuthorization,
  IAssignDominionToCompanyDto,
} from '@dominion/interfaces';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

const apiBaseUrl = environment.apiBaseUrl;

@Injectable({
  providedIn: 'root',
})
export class CompanyService {
  constructor(
    private authService: AuthenticationService,
    private http: HttpClient,
  ) {}

  createCompany(dto: ICompanyCreateDto): Observable<void> {
    return this.http.post<void>(
      `${apiBaseUrl}company`,
      dto,
      this.authService.httpOptions,
    );
  }

  getCompanies(
    page: number,
    search?: string,
  ): Observable<ICompanyReadBriefMeta> {
    const url = `${apiBaseUrl}company/${page}`;

    let params = new HttpParams();
    if (search) {
      params = params.set('search', search);
    }

    return this.http.get<ICompanyReadBriefMeta>(url, {
      params,
      ...this.authService.httpOptions,
    });
  }

  getCompany(id: string): Observable<ICompanyReadFull> {
    return this.http.get<ICompanyReadFull>(
      `${apiBaseUrl}company/id/${id}`,
      this.authService.httpOptions,
    );
  }

  updateCompany(id: string, dto: ICompanyUpdateDto): Observable<void> {
    return this.http.patch<void>(
      `${apiBaseUrl}company/${id}`,
      dto,
      this.authService.httpOptions,
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  inviteUser(dto: IInvitedUserCreate): any {
    return this.http.post<string>(
      `${apiBaseUrl}users/invite`,
      dto,
      this.authService.httpOptions,
    );
  }

  getMyCompanies(): Observable<ICompanyReadExternalBrief[]> {
    return this.http.get<ICompanyReadExternalBrief[]>(
      `${apiBaseUrl}company/my-companies`,
      this.authService.httpOptions,
    );
  }

  addModuleToCompany(
    dto: ICoreModuleCreate,
  ): Observable<ICoreModuleReadBrief | void> {
    return this.http.patch<ICoreModuleReadBrief | void>(
      `${apiBaseUrl}company/add-module`,
      dto,
      this.authService.httpOptions,
    );
  }

  updateModuleDetails(dto: ICoreModuleUpdate): Observable<void> {
    return this.http.patch<void>(
      `${apiBaseUrl}company/module-details`,
      dto,
      this.authService.httpOptions,
    );
  }

  updateSubmoduleDetails(dto: ISubmoduleUpdate): Observable<void> {
    return this.http.patch<void>(
      `${apiBaseUrl}company/submodule-details`,
      dto,
      this.authService.httpOptions,
    );
  }

  assignUserToModule(dto: ICoreModuleAddUserDto): Observable<void> {
    return this.http.patch<void>(
      `${apiBaseUrl}company/assign-module-user`,
      dto,
      this.authService.httpOptions,
    );
  }

  getDominionAssignables(): Observable<IDominionAssignables> {
    return this.http.get<IDominionAssignables>(
      `${apiBaseUrl}users/dominion-assignables`,
      this.authService.httpOptions,
    );
  }

  assignInternalUserToCompany(
    dto: IAssignDominionToCompanyDto,
  ): Observable<void> {
    return this.http.post<void>(
      `${apiBaseUrl}company/assign-internal-user`,
      dto,
      this.authService.httpOptions,
    );
  }

  getSubmoduleData(
    dto: IGetSubmoduleDataDto,
  ): Observable<ICoreSubmodule<TSubmoduleDiscriminators>> {
    return this.http.post<ICoreSubmodule<TSubmoduleDiscriminators>>(
      `${apiBaseUrl}company/submodule`,
      dto,
      this.authService.httpOptions,
    );
  }

  saveSubmoduleResponse(dto: ISubmoduleResponse): Observable<void> {
    return this.http.patch<void>(
      `${apiBaseUrl}company/submodule-response`,
      dto,
      this.authService.httpOptions,
    );
  }

  getSubmoduleFields(
    dto: IGetSubmoduleFieldsBase,
  ): Observable<{ [key: string]: any }> {
    return this.http.post<{ [key: string]: any }>(
      `${apiBaseUrl}company/submodule-fields`,
      dto,
      this.authService.httpOptions,
    );
  }

  getCompaniesOfUser(targetUserId: string): Observable<ICompanyReadBrief[]> {
    return this.http.get<ICompanyReadBrief[]>(
      `${apiBaseUrl}company/user-companies/${targetUserId}`,
      this.authService.httpOptions,
    );
  }

  getModule(dto: IGetModuleDto): Observable<ICoreModule> {
    return this.http.post<ICoreModule>(
      `${apiBaseUrl}company/module`,
      dto,
      this.authService.httpOptions,
    );
  }

  uploadFile(dto: FormData): Observable<IFileUploadReference> {
    return this.http.post<IFileUploadReference>(
      `${apiBaseUrl}file-upload`,
      dto,
      this.authService.httpOptions,
    );
  }

  getFile(dto: IFileGetDto): Observable<any> {
    return this.http.put<any>(
      `${apiBaseUrl}file-upload/file`,
      dto,
      this.authService.fileHttpOptions as any,
    );
  }

  deleteFile(dto: IFileDeleteDto): Observable<void> {
    return this.http.patch<void>(
      `${apiBaseUrl}file-upload/delete`,
      dto,
      this.authService.httpOptions,
    );
  }

  getCompanyVendors(companyId: string): Observable<ICompanyVendors> {
    return this.http.patch<ICompanyVendors>(
      `${apiBaseUrl}vendor/company-vendors`,
      { companyId },
      this.authService.httpOptions,
    );
  }

  authorizeVendors(dto: IAuthorizeVendorsDto) {
    return this.http.patch<VendorAuthorization>(
      `${apiBaseUrl}company/authorize-vendors`,
      dto,
      this.authService.httpOptions,
    );
  }

  getUserAssignments(
    userId: string,
  ): Observable<UserExternalAssignments[] | UserInternalAssignments[]> {
    return this.http.get<UserExternalAssignments[] | UserInternalAssignments[]>(
      `${apiBaseUrl}company/user-assignments/${userId}`,
      this.authService.httpOptions,
    );
  }

  setExternalUserAuthRole(dto: IUserExternalAuthDto): Observable<void> {
    return this.http.post<void>(
      `${apiBaseUrl}users/external-user-auth-role`,
      dto,
      this.authService.httpOptions,
    );
  }
}
