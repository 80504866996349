import { Pipe, PipeTransform } from '@angular/core';
import { TGenericQStruct } from '@dominion/interfaces';

@Pipe({
  name: 'promptPipe',
  standalone: true,
})
export class PromptPipe implements PipeTransform {
  transform(value: TGenericQStruct<string>['prompt']): string {
    return value.map((prompt) => prompt.text).join('');
  }
}
