@if (module && _discriminator && group) {
  <dominion-discovery-group-title
    [module]="module.moduleType"
    [discriminator]="_discriminator"
    [group]="group.groupLabel"
  ></dominion-discovery-group-title>
}

@if (group && data) {
  @switch (group.groupType) {
    @case ('standard') {
      <dominion-standard-group
        @firstQuestion
        [group]="group"
        [data]="data"
        [companyid]="_companyid"
        [moduleid]="_moduleid"
        [submoduleid]="_submoduleid"
        [structure]="structure"
      ></dominion-standard-group>
    }
    @case ('vendor') {
      <dominion-vendor-group
        [companyId]="_companyid"
        [moduleId]="_moduleid"
        [submoduleId]="_submoduleid"
        [group]="group"
      ></dominion-vendor-group>
    }
  }
}
