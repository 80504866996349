<div class="flex h-10 flex-col overflow-hidden px-2">
  <div
    dominionPopoverHost
    [hoverTrigger]="true"
    class="flex h-full cursor-pointer items-center text-sm text-gray-500"
    (click)="goToDashboard()"
  >
    <div class="mr-1 flex w-4 items-center">
      <dominion-icon-company></dominion-icon-company>
    </div>
    <div
      dominionPopoverTarget
      class="flex flex-grow overflow-hidden text-xs md:text-sm lg:text-base"
    >
      <div class="truncate">
        {{ company?.name }}
      </div>
    </div>
    <div dominionPopover class="text-dms-teal">{{ company?.name }}</div>
  </div>
</div>

<ul role="list text-sm lg:text-base">
  <li>
    <button
      type="button"
      (click)="goToDashboard()"
      class="text-dms-teal border-r-dms-green flex w-full cursor-pointer items-center space-x-2 border-y border-l py-2 pl-2 pr-1 text-xs font-semibold md:pl-4 md:text-sm lg:pl-5 lg:text-base"
    >
      Dashboard
    </button>
  </li>
  <li>
    <a
      [routerLink]="['/core', companyId, 'vendors']"
      [routerLinkActive]="'active'"
      class="text-dms-teal [&.active]:text-dms-green border-r-dms-green flex cursor-pointer items-center space-x-2 border-b border-l py-2 pl-2 pr-1 text-xs font-semibold md:pl-4 md:text-sm lg:pl-5 lg:text-base [&.active]:border-r-2"
    >
      Vendors
    </a>
  </li>
  <li>
    <a
      [routerLink]="['/core', companyId, 'documents']"
      [routerLinkActive]="'active'"
      class="text-dms-teal [&.active]:text-dms-green border-r-dms-green flex cursor-pointer items-center space-x-2 border-b border-l py-2 pl-2 pr-1 text-xs font-semibold md:pl-4 md:text-sm lg:pl-5 lg:text-base [&.active]:border-r-2"
    >
      Documents
    </a>
  </li>

  <li
    class="text-dms-teal flex flex-col overflow-hidden border-b border-l pl-2 md:pl-4 lg:pl-5"
  >
    <h3
      class="border-r-dms-green flex select-none items-center space-x-2 py-2 text-xs font-semibold md:text-sm lg:text-base"
      [ngClass]="{ 'text-dms-green border-r-2': !!moduleId }"
    >
      Modules
    </h3>
    <ul class="flex flex-col pb-1.5 pl-1 md:pl-2 xl:pl-4">
      @for (module of modules; track module._id) {
        <li>
          <a
            [routerLink]="['/core', companyId, 'module', module._id]"
            [routerLinkActive]="'active'"
            class="[&.active]:text-dms-green mb-1 flex cursor-pointer truncate text-xs text-gray-600 disabled:text-gray-400 lg:text-sm xl:uppercase [&.active]:font-semibold"
          >
            {{ module.moduleType | moduleType }}
          </a>
        </li>
      }
    </ul>
  </li>
</ul>
