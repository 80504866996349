<div class="h-full w-full">
  <div class="mb-4 h-full w-full p-6">
    <div class="mb-4 flex flex-row space-x-2 md:space-x-4 lg:space-x-10">
      <!-- COMPANY INFO -->
      <div class="max-w-2/5 flex w-2/5 flex-initial flex-col">
        <!-- COMPANY NAME -->
        <div class="flex flex-row text-lg font-bold lg:text-3xl">
          <dominion-in-situ-text
            #name
            [initialValue]="company?.name"
            [isDisabled]="!canEditCompanyDetails"
            [maxLength]="40"
            [minLength]="8"
            defaultValue="Company"
            formErrMsg="Please enter a company name"
            (saved)="onCompanyUpdate({ field: 'name', value: $event }, name)"
          ></dominion-in-situ-text>
        </div>

        <!-- COMPANY ADDRESS -->
        <div class="flex flex-col pl-4 text-sm lg:pl-8 lg:text-lg">
          <div class="flex flex-row">
            <dominion-in-situ-text
              #street
              [initialValue]="company?.address?.street"
              [isDisabled]="!canEditCompanyDetails"
              [maxLength]="40"
              defaultValue="Street Address"
              formErrMsg="Please enter a street address"
              (saved)="
                onCompanyUpdate({ field: 'street', value: $event }, street)
              "
            ></dominion-in-situ-text>
          </div>
          <div class="flex items-center">
            <dominion-in-situ-text
              #city
              [initialValue]="company?.address?.city"
              [isDisabled]="!canEditCompanyDetails"
              [maxLength]="30"
              defaultValue="City"
              formErrMsg="Please enter a city"
              (saved)="onCompanyUpdate({ field: 'city', value: $event }, city)"
            ></dominion-in-situ-text>

            <span>,</span>

            <dominion-in-situ-select
              #state
              [config]="{ viewType: 'editable' }"
              [initialValue]="company?.address?.state"
              [options]="stateOptions"
              defaultValue="State"
              (saved)="
                onCompanyUpdate({ field: 'state', value: $event }, state)
              "
              [isDisabled]="!canEditCompanyDetails"
            ></dominion-in-situ-select>

            <dominion-in-situ-text
              #zip
              [initialValue]="company?.address?.zip"
              [isDisabled]="!canEditCompanyDetails"
              [maxLength]="5"
              [minLength]="5"
              defaultValue="Zip"
              formErrMsg="Please enter a 5-digit zip code"
              (saved)="onCompanyUpdate({ field: 'zip', value: $event }, zip)"
            ></dominion-in-situ-text>
          </div>
        </div>
      </div>

      <!-- PROJECT INFO -->
      <div class="flex basis-3/5 flex-row">
        <div class="flex flex-1 flex-col rounded bg-gray-100 p-2">
          <div class="mb-1 flex shrink flex-row justify-center pb-1">
            <div class="flex text-sm uppercase text-gray-500">
              Project Details
            </div>
          </div>
          <div class="flex flex-col">
            <div class="flex flex-row space-x-1">
              <div class="flex basis-1/4 flex-col p-1 lg:p-2">
                <div
                  dominionInfoLabel
                  class="flex flex-row items-center justify-center space-x-1"
                >
                  <span class="shrink-0">Go-Live</span>
                  <dominion-information-button
                    [strokeColor]="'stroke-gray-600 hover:stroke-white'"
                  >
                    <div class="w-64 p-2">
                      <p>
                        The <strong>Go-Live Date</strong> is the day that your
                        dealership begins using VUE for day-to-day business.
                      </p>
                    </div>
                  </dominion-information-button>
                </div>
                <div dominionValue class="flex flex-row justify-center">
                  <dominion-in-situ-text
                    #gld
                    [initialValue]="company?.goLiveDate | date: 'MM/dd/yyyy'"
                    [isDisabled]="!canEditCompanyDetails"
                    [maxLength]="10"
                    [minLength]="10"
                    defaultValue="None"
                    formErrMsg="Please enter a valid date (MM/DD/YYYY)"
                    type="date"
                    (saved)="
                      onCompanyUpdate(
                        { field: 'goLiveDate', value: $event },
                        gld
                      )
                    "
                  ></dominion-in-situ-text>
                </div>
              </div>
              <div class="flex basis-1/4 flex-col p-1 lg:p-2">
                <div dominionInfoLabel class="flex flex-row justify-center">
                  DMS
                </div>
                <div dominionValue class="flex flex-row justify-center">
                  @if (company) {
                    <dominion-in-situ-select
                      #incumbent
                      [config]="{ viewType: 'editable' }"
                      [initialValue]="company.dms"
                      [options]="IDMSOptions"
                      defaultValue="None"
                      (saved)="
                        onCompanyUpdate(
                          { field: 'dms', value: $event },
                          incumbent
                        )
                      "
                      [isDisabled]="!canEditCompanyDetails"
                    ></dominion-in-situ-select>
                  }
                </div>
              </div>
              <div class="flex basis-1/4 flex-col p-1 lg:p-2">
                <div
                  dominionInfoLabel
                  class="flex flex-row items-center justify-center space-x-1"
                >
                  <span>CED</span>
                  <dominion-information-button
                    [strokeColor]="'stroke-gray-600 hover:stroke-white'"
                  >
                    <div class="w-64 p-2">
                      <p>
                        The <strong>Contract Expiration Date</strong> (CED) is
                        the date that your contract with your current DMS
                        provider expires.
                      </p>
                    </div>
                  </dominion-information-button>
                </div>
                <div dominionValue class="flex flex-row justify-center">
                  <dominion-in-situ-text
                    #ced
                    [initialValue]="
                      company?.contractExpirationDate | date: 'MM/dd/yyyy'
                    "
                    [isDisabled]="!canEditCompanyDetails"
                    [maxLength]="10"
                    [minLength]="10"
                    defaultValue="None"
                    formErrMsg="Please enter a valid date (MM/DD/YYYY)"
                    type="date"
                    (saved)="
                      onCompanyUpdate(
                        { field: 'contractExpirationDate', value: $event },
                        ced
                      )
                    "
                  ></dominion-in-situ-text>
                </div>
              </div>
              <div class="flex basis-1/4 flex-col p-1 lg:p-2">
                <div
                  dominionInfoLabel
                  class="flex flex-row items-center justify-center space-x-1"
                >
                  <span>PID</span>
                  <dominion-information-button
                    [strokeColor]="'stroke-gray-600 hover:stroke-white'"
                  >
                    <div class="w-64 p-2">
                      <p>
                        The <strong>Preferred Install Date</strong> is the date
                        that you would like to begin using VUE for day-to-day
                        business.
                      </p>
                    </div>
                  </dominion-information-button>
                </div>
                <div dominionValue class="flex flex-row justify-center">
                  <dominion-in-situ-text
                    #pid
                    [initialValue]="
                      company?.preferredInstallDate | date: 'MM/dd/yyyy'
                    "
                    [isDisabled]="!canEditCompanyDetails"
                    [maxLength]="10"
                    [minLength]="10"
                    defaultValue="None"
                    formErrMsg="Please enter a valid date (MM/DD/YYYY)"
                    type="date"
                    (saved)="
                      onCompanyUpdate(
                        { field: 'preferredInstallDate', value: $event },
                        pid
                      )
                    "
                  ></dominion-in-situ-text>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- COMPANY PROJECT -->
    <div class="mt-8 grid grid-cols-3 gap-6">
      <dominion-company-project
        class="col-span-3"
        [isLoading]="isLoading"
        [internalView]="internalView"
        [company]="company"
        [companyId]="company?._id"
        [isParentCompany]="
          (company &&
            company.childCompanies &&
            company.childCompanies.length > 0) ||
          false
        "
        [canAddModule]="canAddModule"
        [modules]="company?.modules"
        [canEditModuleDetails]="canEditModuleDetails"
        [canSeeInternalDetails]="canSeeInternalDetails"
        [canAssignModuleUser]="canAssignModuleUser"
        (emitAddModule)="addModuleToCompany($event)"
        (emitUpdateModuleDetails)="updateModuleDetails($event)"
        (emitUpdateSubmoduleDetails)="updateSubmoduleDetails($event)"
        (emitAssignUserToModule)="assignUserToModule($event)"
      ></dominion-company-project>

      <dominion-company-users
        class="col-span-full lg:col-span-2"
        [user]="user"
        [company]="company"
        [isLoading]="isLoading"
        [canEditCompanyDetails]="canEditCompanyDetails"
        [canInviteSuper]="canInviteSuper"
        [canInviteAdmin]="canInviteAdmin"
        [canInviteManager]="canInviteManager"
        (emitAddUser)="inviteUser($event)"
        [internalView]="internalView"
      ></dominion-company-users>

      <dominion-company-related-companies
        class="col-span-3 md:col-span-2 lg:col-span-1"
        [isLoading]="isLoading"
        [company]="company"
        [internalView]="internalView"
      ></dominion-company-related-companies>
    </div>

    <div class="h-20"></div>
  </div>
</div>

<!--

incumbent
cid
pid
contacts
projects
users
date created*
created by*
relationships
  parent
  children
  siblings

-->
