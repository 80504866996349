<div class="flex h-full w-full flex-col">
  <div class="flex flex-row items-center p-4">
    <div dominionCoreOutletTitle class="flex-row items-center">Companies</div>
    <div class="ml-2 flex">
      <dominion-core-create-btn
        routerLink="/core/admin/companies/create-company"
        data-testid="create-company-btn"
      >
      </dominion-core-create-btn>
    </div>
  </div>
  <div class="flex-1 overflow-hidden p-4">
    <div class="flex h-full w-full flex-col">
      <div class="flex flex-wrap justify-between gap-4">
        <dominion-table-nav
          [page]="page"
          [pages]="pages"
          [results]="companyCount"
          [resultsPerPage]="resultsPerPage"
          (firstPage)="firstPage()"
          (nextPage)="nextPage()"
          (prevPage)="previousPage()"
          (lastPage)="lastPage()"
        ></dominion-table-nav>

        <dominion-search-bar
          (emitStartSearch)="isLoadingCompanies = true"
          (emitFilterValue)="searchCompanies($event)"
          class="w-full min-w-[48px] max-w-xs"
        ></dominion-search-bar>
      </div>

      <div class="h-6"></div>

      <dominion-table
        id="admin-companies"
        [isLoading]="isLoadingCompanies"
        [isEmpty]="companies.length === 0"
      >
        <thead>
          <tr>
            <th scope="col">Company</th>
            <th scope="col">DMS</th>
            <th scope="col">Go-Live</th>
            <th scope="col">CED</th>
            <th scope="col">PID</th>
            <th scope="col"><span class="sr-only">View</span></th>
          </tr>
        </thead>
        <tbody>
          @for (company of companies; track company._id) {
            <tr
              [routerLink]="['/core/admin/companies', company._id, 'detail']"
              [attr.data-testid]="'company-row-' + $index"
            >
              <td>
                <div class="flex flex-col justify-center gap-0.5">
                  <div class="font-semibold">
                    {{ company.name }}
                  </div>
                  @if (company.address) {
                    <div class="text-xs font-medium text-gray-500">
                      {{ company.address.street }}, {{ company.address.city }},
                      {{ company.address.state }}
                    </div>
                  }
                </div>
              </td>
              <td>
                @if (company.dms) {
                  {{ company.dms | dms }}
                } @else {
                  <span class="text-gray-400">&mdash;</span>
                }
              </td>
              <td>
                @if (company.goLiveDate) {
                  {{ company.goLiveDate | date }}
                } @else {
                  <span class="text-gray-400">&mdash;</span>
                }
              </td>
              <td>
                @if (company.contractExpirationDate) {
                  {{ company.contractExpirationDate | date }}
                } @else {
                  <span class="text-gray-400">&mdash;</span>
                }
              </td>
              <td>
                @if (company.preferredInstallDate) {
                  {{ company.preferredInstallDate | date }}
                } @else {
                  <span class="text-gray-400">&mdash;</span>
                }
              </td>
              <td>
                <a
                  [routerLink]="[
                    '/core/admin/companies',
                    company._id,
                    'detail'
                  ]"
                  class="text-dms-green hover:bg-dms-light-green/20 group flex h-7 w-7 cursor-pointer items-center truncate rounded-sm p-[6px] text-sm font-medium"
                >
                  <dominion-icon-go-to
                    strokeColor="stroke-dms-green group-hover:stroke-dms-dark-green"
                  ></dominion-icon-go-to>
                </a>
              </td>
            </tr>
          }
        </tbody>
      </dominion-table>
    </div>
  </div>
</div>
