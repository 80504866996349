import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { accountingPreliminaryStructure } from 'libs/interfaces/src/lib/modules/accounting/prelim/accounting-prelim-data.interfaces';
import { StandardGroupComponent } from '../standard-group/standard-group.component';
import { VendorGroupComponent } from '../vendor-group/vendor-group.component';
import {
  DataInitializerBasic,
  DataInitializerOther,
  IGetModuleDto,
  IGetSubmoduleFieldsBase,
  ICoreModule,
  Structures,
  TAllGroups,
  TSubmoduleDiscriminators,
  infraPreliminaryStructure,
  isKeyOf,
  partsPreliminaryStructure,
  servicePreliminaryStructure,
} from '@dominion/interfaces';
import { CompanyService } from '../company/company.service';
import { payrollPreliminaryStructure } from 'libs/interfaces/src/lib/modules/payroll/prelim/payroll-prelim-data.interfaces';
import { fiPreliminaryStructure } from 'libs/interfaces/src/lib/modules/fi/prelim/fi-prelim-data.interfaces';
import { salesPreliminaryStructure } from 'libs/interfaces/src/lib/modules/sales/prelim/sales-prelim-data.interfaces';
import { DiscoveryGroupDirective } from '../shared/discovery-group/discovery-group.directive';
import { DiscoveryGroupTitleComponent } from '../shared/discovery-group-title/discovery-group-title.component';
import { firstQuestionAnimation } from '../shared/animations/first-question.animation';
import { partsMaintenanceStructure } from 'libs/interfaces/src/lib/modules/parts/maintenance/parts-maintenance-data.interfaces';
import { accountingMaintenanceStructure } from 'libs/interfaces/src/lib/modules/accounting/maintenance/accounting-maintenance-data.interfaces';
import { payrollMaintenanceStructure } from 'libs/interfaces/src/lib/modules/payroll/maintenance/payroll-maintenance-data.interfaces';
import { serviceMaintenanceStructure } from 'libs/interfaces/src/lib/modules/service/maintenance/service-maintenance-data.interfaces';
import { salesMaintenanceStructure } from 'libs/interfaces/src/lib/modules/sales/maintenance/sales-maintenance-data.interfaces';
import { salesDefaultStructure } from 'libs/interfaces/src/lib/modules/sales/default/sales-default-data.interfaces';
import { serviceDefaultStructure } from 'libs/interfaces/src/lib/modules/service/default/service-default-data.interfaces';
import { accountingDefaultStructure } from 'libs/interfaces/src/lib/modules/accounting/default/accounting-default-data.interfaces';
import { partsDefaultStructure } from 'libs/interfaces/src/lib/modules/parts/default/parts-default-data.interfaces';

const structureMap = {
  'accounting-preliminary': accountingPreliminaryStructure,
  'accounting-maintenance': accountingMaintenanceStructure,
  'infrastructure-preliminary': infraPreliminaryStructure,
  'parts-preliminary': partsPreliminaryStructure,
  'parts-maintenance': partsMaintenanceStructure,
  'payroll-preliminary': payrollPreliminaryStructure,
  'payroll-maintenance': payrollMaintenanceStructure,
  'service-preliminary': servicePreliminaryStructure,
  'service-maintenance': serviceMaintenanceStructure,
  'fi-preliminary': fiPreliminaryStructure,
  'sales-preliminary': salesPreliminaryStructure,
  'sales-maintenance': salesMaintenanceStructure,
  'sales-default': salesDefaultStructure,
  'service-default': serviceDefaultStructure,
  'accounting-default': accountingDefaultStructure,
  'parts-default': partsDefaultStructure,
};

@Component({
  selector: 'dominion-group-container',
  standalone: true,
  imports: [
    CommonModule,
    StandardGroupComponent,
    VendorGroupComponent,
    DiscoveryGroupTitleComponent,
  ],
  templateUrl: './group-container.component.html',
  styleUrls: ['./group-container.component.css'],
  hostDirectives: [DiscoveryGroupDirective],
  animations: [firstQuestionAnimation],
})
export class GroupContainerComponent implements OnInit {
  _companyid: string;
  _groupUrl: string;
  _moduleid: string;
  _submoduleid: string;
  _discriminator: TSubmoduleDiscriminators;
  module: ICoreModule;
  structure: Structures[TSubmoduleDiscriminators];
  group: TAllGroups;
  data: {
    [key: string]: DataInitializerBasic<any> | DataInitializerOther<any>;
  };

  @Input() set companyid(companyid: string) {
    this._companyid = companyid;
  }

  @Input() set moduleid(moduleid: string) {
    this._moduleid = moduleid;
  }

  @Input() set submoduleid(submoduleid: string) {
    this._submoduleid = submoduleid;
  }

  @Input() set groupUrl(groupUrl: string) {
    this._groupUrl = groupUrl;
  }

  @Input() set discriminator(discriminator: TSubmoduleDiscriminators) {
    this._discriminator = discriminator;
  }

  constructor(private companyService: CompanyService) {}

  ngOnInit(): void {
    this.getModule();
  }

  buildGetDataDto() {
    if (this._companyid && this._submoduleid && this._moduleid) {
      const fields = Object.keys(this.group.questions);
      const dto: IGetSubmoduleFieldsBase = {
        submoduleId: this._submoduleid,
        fields: fields,
      };
      return dto;
    }
    return null;
  }

  getData() {
    const dto = this.buildGetDataDto();
    if (dto) {
      this.companyService.getSubmoduleFields(dto).subscribe({
        next: (data) => {
          this.setData(data, dto.fields);
        },
      });
    }
  }

  // This function will set any undefined data keys to a default value
  setData(data: Record<string, any>, keys: string[]) {
    const undefinedKeys = keys.filter((key) => !data[key]);
    undefinedKeys.forEach((key) => {
      data[key] = new DataInitializerBasic();
    });
    this.data = data;
  }

  getModule() {
    if (this._moduleid) {
      const dto: IGetModuleDto = {
        companyId: this._companyid,
        moduleId: this._moduleid,
      };
      this.companyService.getModule(dto).subscribe({
        next: (module) => {
          this.module = module;
          this.setStructure();
        },
      });
    }
  }

  setStructure() {
    if (this.module && this._discriminator) {
      this.structure = structureMap[this._discriminator];
      this.setGroup();
    }
  }

  setGroup() {
    if (this._groupUrl) {
      if (isKeyOf(this.structure.groups, this._groupUrl)) {
        this.group = this.structure.groups[this._groupUrl];
        this.getData();
      }
    }
  }
}
