import { Pipe, PipeTransform } from '@angular/core';
import { TGenericGroup } from '@dominion/interfaces';

@Pipe({
  name: 'groupPipe',
  standalone: true,
})
export class GroupPipe implements PipeTransform {
  transform(value: {
    [key: string]: TGenericGroup<string>;
  }): TGenericGroup<string>[] {
    return Object.values(value);
  }
}
