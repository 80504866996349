import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconEllipsisComponent } from '../../icons/icon-ellipsis.component';
import { IconCheckComponent } from '../../icons/icon-check.component';
import { IconLockLockedComponent } from '../../icons/icon-lock-locked.component';
import { PopoverHostDirective } from '../directives/popover-host.directive';
import { PopoverDirective } from '../directives/popover.directive';
import { PopoverTargetDirective } from '../directives/popover-target.directive';

@Component({
  selector: 'dominion-module-status-circle',
  standalone: true,
  imports: [
    CommonModule,
    IconEllipsisComponent,
    IconCheckComponent,
    IconLockLockedComponent,
    PopoverHostDirective,
    PopoverDirective,
    PopoverTargetDirective,
  ],
  templateUrl: './module-status-circle.component.html',
  styleUrls: ['./module-status-circle.component.css'],
})
export class ModuleStatusCircleComponent {
  @Input() completion: number = 0;
  @Input() isLocked: boolean = true;
  @Input() canEditModuleDetails: boolean = false;
  @Input() stage: number;
}
